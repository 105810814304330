<template>
  <div>
    {{ link }}
    <v-row>
      <!-- table -->
      <v-col cols="12" md="12">
        <v-data-table
          :headers="invoiceProductsHeader"
          :items="invoiceProducts"
          :items-per-page="5"
          class="elevation-1 "
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-7">
              <!-- delete item -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-slot:item.actions="{ item }"
            style="textAlign:right !important"
          >
            <v-icon
              color="primary"
              small
              class="mr-2"
              @click="editRecord(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              color="error"
              small
              class="mr-2"
              @click="deleteRecord(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <!-- invoice -->
      <v-col
        cols="12"
        md="12"
        style="padding:50px; background-color: white;"
        id="app5"
      >
        <table style="width:100%">
          <tr>
            <td style="width:20%;">
              <img src="/Diwan Book - QR.jpeg" style="width:100px" />
            </td>
            <td style="width:60% ;text-align:center">
              {{ invoiceDate }} / {{ invoiceTime }}<br />
              رقم الفاتوره : {{ $route.params.id }}
            </td>
            <td style="width:20%;">
              <img src="/Icon.png" style="width:100px;float: right;" />
            </td>
          </tr>
        </table>

        <v-data-table
          :headers="invoiceProductsHeader1"
          :items="invoiceProducts"
          :items-per-page="-1"
          class="elevation-1 "
        >
        </v-data-table>

        <table
          style="width:100%; border: 1px solid #e0e0e0;margin-top:20px
"
        >
          <tr>
            <td style="width:50%; padding: 10px;">
              <span
                style="font-size: 20px;
    font-family: bold;"
                >Subtotal :</span
              >
            </td>
            <td style="width:50%; padding: 10px;">
              <span
                style="font-size: 20px;
    font-family: bold;"
                >{{ subtotal }} TRY</span
              >
            </td>
          </tr>
          <tr v-if="discount">
            <td style="width:50%; padding: 10px;">
              <span
                style="font-size: 20px;
    font-family: bold;"
                >Discount :</span
              >
            </td>
            <td style="width:50%; padding: 10px;">
              <span
                style="font-size: 20px;
    font-family: bold;"
                >{{ discount }} TRY</span
              >
            </td>
          </tr>
          <tr>
            <td style="width:50%; padding: 10px;">
              <span
                style="font-size: 20px;
    font-family: bold;"
                >Grand Total :</span
              >
            </td>
            <td style="width:50%; padding: 10px;">
              <span
                style="font-size: 20px;
    font-family: bold;"
                >{{ grandTotal }} TRY</span
              >
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <!-- form -->
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">Products</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col md="12">
            <v-text-field
              placeholder="discount"
              label="discount"
              v-model="discount"
              @change="discountChanged"
            />
          </v-col>
          <v-col md="12">
            <v-text-field
              placeholder="first name"
              label="first name"
              v-model="customer.fname"
            />
          </v-col>
          <v-col md="12">
            <v-text-field
              placeholder="last name"
              label="last name"
              v-model="customer.lname"
            />
          </v-col>
          <v-col md="12">
            <v-text-field
              placeholder="phone"
              label="phone"
              v-model="customer.phone"
            />
          </v-col>
          <v-col md="12">
            <v-text-field
              placeholder="email"
              label="email"
              v-model="customer.email"
            />
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" v-if="invoiceProductForm.id">
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="product_id"
                :items="products"
                multiple
                outlined
                dense
                style="padding:10px,margin: 10px"
                chips
                small-chips
                label="product"
                item-text="arabic_title"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                placeholder="quantity"
                label="quantity"
                v-model="invoiceProductForm.quantity"
                :rules="[(v) => !!v || 'order is required']"
              />
            </v-col>
            <!-- <v-col md="8">
              <v-text-field
                placeholder="discount"
                label="discount"
                v-model="invoiceProductForm.discount"
              />
            </v-col> -->
          </v-row>
        </v-form>
        <v-form ref="form" v-model="valid" v-else>
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="productsItems"
                :items="products"
                multiple
                dense
                chips
                small-chips
                label="product"
                item-text="arabic_title"
                return-object
              ></v-autocomplete>
            </v-col> </v-row
          ><v-row
            v-for="productItem in productsItems"
            v-bind:key="productItem.id"
          >
            <v-col cols="12" md="6">{{ productItem.arabic_title }} </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                placeholder="quantity defult 1"
                label="quantity defult 1"
                v-model="productItem.quantity"
              />
              <!-- :rules="[(v) => !!v || 'order is required']" -->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="blue darken-1" text @click="submit">
          Submit
        </v-btn>

        <v-btn color="green darken-1" text v-print="'#app5'">
          print
        </v-btn>
        <v-btn color="green darken-1" text @click="goOutto">
          e-pay
        </v-btn>
        <v-col cols="12" md="10">
          <v-overlay :value="loading">
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular> </v-overlay
        ></v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import axios from "axios";

export default {
  props: ["tag_id", "dialog"],
  data() {
    return {
      customer: { fname: null, lname: null, phone: null, email: null },
      link: null,
      discountPercentage: 0,
      invoiceDate: null,
      invoiceTime: null,
      productsItems: [],
      product_id: null,
      valid: false,
      loading: false,
      record: null,
      dialogDelete: false,
      products: [],
      grandTotal: 0,
      subtotal: 0,
      invoiceProducts: [],
      currency: null,
      textRules: [(v) => !!v || "field is required"],
      invoiceProductsHeader: [
        { text: "product", value: "product_id.arabic_title" },
        { text: "quantity", value: "quantity" },
        { text: "original price", value: "original" },
        { text: "discounted price (35%)", value: "price" },
        // { text: "discount", value: "discount" },
        // { text: "dkprice", value: "dkprice" },
        { text: "subtotal", value: "subtotal" },
        // { text: "dksubtotal", value: "dksubtotal" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      invoiceProductsHeader1: [
        { text: "product", value: "product_id.arabic_title" },
        { text: "quantity", value: "quantity" },
        { text: "original price", value: "original" },
        { text: "discounted price (35%)", value: "price" }, // { text: "discount", value: "discount" },
        // { text: "dkprice", value: "dkprice" },
        { text: "subtotal", value: "subtotal" },
        // { text: "dksubtotal", value: "dksubtotal" },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      discount: null,
      hasDiscount: false,
      invoiceProductForm: {
        id: null,
        quantity: 1,
        // discount: null,
        // product_id: null,
        // price: null,
        // invoice_id: null,
      },
    };
  },
  methods: {
    async goOutto() {
      let item = {};
      item["amount"] = (this.grandTotal / this.currency.to).toFixed(2);
      item["currency_code"] = "KWD";
      item["gateway_code"] = "credit-card";
      item["order_no"] = this.$route.params.id;
      item["customer_email"] = this.customer.email;
      item["disclosure_url"] =
        "https://diwanworld.app/diwanapi/api/public/diwan/custom/fairpayment";
      item["redirect_url"] = "https://diwanworld.com";
      item["email_payment_details"] = true;
      item["sms_payment_details"] = true;
      item["customer_phone"] = this.customer.phone;
      item["customer_first_name"] = this.customer.fname;
      item["customer_last_name"] = this.customer.lname
        ? this.customer.lname
        : "";
      let amount = (this.grandTotal / this.currency.to).toFixed(2);
      this.link = `https://diwanworld.app/fair.html?amount=${amount}&order_no=${this.$route.params.id}&customer_email=${this.customer.emai}&customer_phone=${this.customer.phone}&customer_first_name=${this.customer.fname}`;

      const response = await ApiService.post(
        `custom/fairmail?amount=${amount}&order_no=${this.$route.params.id}&customer_email=${this.customer.email}&customer_phone=${this.customer.phone}&customer_first_name=${this.customer.fname}&email=${this.customer.email}`,
        {}
      );

      console.log(response);
      // this.link = response.data.url;
      // window.open(this.link, "_blank");
    },
    discountChanged() {
      this.discount ? (this.hasDiscount = true) : (this.hasDiscount = false);
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          let newPrice;

          if (this.invoiceProductForm.id) {
            newPrice =
              this.product_id.discounted_price * this.currency.from -
              (this.product_id.discounted_price *
                this.currency.from *
                this.discountPercentage) /
                100;
            //product price
            this.invoiceProductForm["price"] = newPrice;
            this.invoiceProductForm["dkprice"] = (
              newPrice / this.currency.to
            ).toFixed(2);
            //subtotal
            this.invoiceProductForm["subtotal"] =
              newPrice * this.invoiceProductForm.quantity;
            this.invoiceProductForm["dksubtotal"] = (
              (newPrice * this.invoiceProductForm.quantity) /
              this.currency.to
            ).toFixed(2);

            this.invoiceProductForm["product_id"] = this.product_id.id;
            const updateProductReq = await ApiService.update(
              "items/product_invoice",
              this.invoiceProductForm.id,
              this.invoiceProductForm
            );
          } else {
            this.invoiceProductForm["invoice_id"] = this.$route.params.id;
            if (this.productsItems.length > 0)
              this.productsItems.forEach(async (element) => {
                this.invoiceProductForm["product_id"] = element.id;
                this.invoiceProductForm["quantity"] = element.quantity
                  ? element.quantity
                  : 1;

                newPrice =
                  element.discounted_price * this.currency.from -
                  (element.discounted_price *
                    this.currency.from *
                    this.discountPercentage) /
                    100;

                this.invoiceProductForm["price"] = newPrice;
                this.invoiceProductForm["dkprice"] = (
                  newPrice / this.currency.to
                ).toFixed(2);
                this.invoiceProductForm["subtotal"] =
                  newPrice * this.invoiceProductForm.quantity;
                this.invoiceProductForm["dksubtotal"] = (
                  (newPrice * this.invoiceProductForm.quantity) /
                  this.currency.to
                ).toFixed(2);
                const createProductReq = await ApiService.post(
                  "items/product_invoice",
                  this.invoiceProductForm
                );
              });
            // if (this.discount) {
            //   const updateInvoiceReq = await ApiService.update(
            //     "items/invoice",
            //     { discount: this.discount },
            //     this.$route.params.id
            //   );
            // }
          }
          if (this.hasDiscount) {
            const updateInvoiceReq = await ApiService.update(
              "items/invoice",
              this.$route.params.id,
              { discount: this.discount }
            );
          }
          this.loading = false;
          this.resetForm();
          this.loadInvoiceProducts();
          let msg;
          msg = "added successfully";
          alert(msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadInvoiceProducts() {
      const products = await ApiService.get(
        `items/product_invoice?fields=id,product_id.id,product_id.discounted_price,invoice_id.id,product_id.arabic_title,quantity,price,currency_code,discount,subtotal,dkprice,dksubtotal,created_on,invoice_id.discount&filter[invoice_id.id]=${this.$route.params.id}`
      );
      products.data.data.forEach((element) => {
        element.original =
          element.product_id.discounted_price * this.currency.from;
      });

      this.invoiceProducts = products.data.data;
      this.discount =
        this.invoiceProducts.length > 0
          ? this.invoiceProducts[0].invoice_id.discount
          : null;

      this.grandTotal = 0;
      this.invoiceProducts.forEach((element) => {
        this.grandTotal = this.grandTotal + parseFloat(element.subtotal);
      });
      this.subtotal = this.grandTotal;
      this.grandTotal = this.grandTotal - this.discount;
    },
    async loadProducts() {
      const products = await ApiService.get(
        "items/products?fields=id,arabic_title,discounted_price&filter[publisher][in]=63,70,74&filter[status]=published"
      );
      this.products = products.data.data;
    },
    async loadSetting() {
      const setting = await ApiService.get(
        "items/setting?fields=fair_discount"
      );
      this.discountPercentage = setting.data.data[0].fair_discount;
    },
    editRecord(record) {
      Object.keys(this.invoiceProductForm).forEach((element) => {
        if (record[element]) {
          if (typeof record[element] == "object") {
            this.invoiceProductForm[element] = record[element].id;
          } else {
            this.invoiceProductForm[element] = record[element];
          }
        }
      });
      let product = this.products.find((p) => p.id === record.product_id.id);

      this.product_id = product;
    },
    resetForm() {
      this.invoiceProductForm.id = null;
      this.invoiceProductForm.quantity = 0;
      this.discount = null;
      this.productsItems = [];
      this.product_id = null;
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.delete(`items/product_invoice/${this.record.id}`);
        this.loadInvoiceProducts();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
      } catch (error) {
        console.log(error);
      }
    },
    deleteRecord(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
    async loadCurrency() {
      const currency = await ApiService.get(
        "items/currency?fields=from,to&filter[title]=TRY"
      );
      this.currency = currency.data.data[0];
      console.log(this.currency);
    },
  },
  async mounted() {
    const d = new Date();
    this.invoiceDate = d.toDateString();
    this.invoiceTime = d.toTimeString();
    this.resetForm();
    this.loadProducts();
    this.loadSetting();
    this.loadCurrency();
    this.loadInvoiceProducts();
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
